<template>
  <main class="home-page">
    <section class="section hero-section">
      <img src="./../assets/img/hero-ellipse.png" class="hero-ellipse hero-image" draggable="false">
      <img src="./../assets/img/hero1.png" class="hero-top hero-image" draggable="false">
      <img src="./../assets/img/hero2.png" class="hero-bottom hero-image" draggable="false">

      <div class="wrapper">

        <div class="left">
          <div class="title big">
            The best place to buy
            <span class="cs2-title">CS2</span> and <span class="tf2-title">TF2</span>
            skins and items
          </div>
          <div class="desc">
            {{ $t(`Heavyskins offers a wide selection of TF2 and CS2 skins and items at competitive prices,
            user-friendly interface, secure payment system, fast delivery and excellent customer service`) }}
          </div>
        </div>

      </div>
    </section>

    <FeaturesSection />

    <div class="section">
      <ImproveSection title="CS2 AND TEAM FORTREST SKINS"
        description="One platform to shop all the skins for both of your favourite games."
        leftImage="img/improve_section_left_bottom.png" rightImage="img/improve_section_right_top.png"
        backgroundColor="#3B5FFF" textColor="#fff" />
    </div>


    <div class="section">
      <div class="wrapper">
        <MoreOffers @goToProduct="goToProduct" />
      </div>
    </div>

    <div class="section">
      <ImproveSection title="Need skins? HEAVYSKINS.COM" leftImage="img/improve_section_left_bottom_2.png"
        rightImage="img/improve_section_right_top_2.png" backgroundColor="#FF4777" textColor="#fff" />
    </div>

    <!-- <ProductCarousel title="Rust and Dota 2 Skins" apiEndpoint="items/rand-type-items" showAllLink="/products/all"
      @goToProduct="goToProduct" /> -->

    <!-- <UnleashSection /> -->

    <!-- <section class="section home-products-section">
      <div class="wrapper">
        <div class="top">
          <div class="title">
            {{ $t('Hot deals') }}
          </div>
          <router-link to="/products/all" class="desc blue">
            {{ $t('View all') }}
          </router-link>
        </div>
        <Transition>
          <div v-if="saleProducts && saleProducts.length" class="products">
            <div v-for="(item, i) in saleProducts.slice(0, 5)" :key="i" class="item">
              <ProductCard :item="item" :currencySymbol="currencySymbol" :sale="true" @goToProduct="goToProduct" />
            </div>
          </div>
        </Transition>
      </div>
    </section> -->

    <!-- <section class="section last-sales-section">
      <LastSales :currencySymbol="currencySymbol" @goToProduct="goToProduct" />
    </section> -->

    <!-- <section class="section packs-of-random-section">
      <RandomCasesSlider :currencySymbol="currencySymbol" :currencyCode="currencyCode" @goToProduct="goToProduct" />
    </section> -->

    <!-- <SecureAndAffordable /> -->


    <!-- <ProductCarousel title="Most Popular" apiEndpoint="items/rand-type-items" showAllLink="/products/all"
      @goToProduct="goToProduct" /> -->

    <!-- <section class="section faq-section" v-if="faq && faq.length">
      <div class="wrapper">
        <FaqSection :faq="faq" title="Frequently Asked Questions" />
      </div>
    </section> -->

    <!-- <PaymentMethodsSection /> -->

    <!-- <WhatsNew /> -->

  </main>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
// import RandomCasesSlider from "../components/RandomCasesSlider.vue";
// import LastSales from "../components/LastSales.vue";
// import StatSection from "../components/StatSection.vue";
// import UnleashSection from "../components/UnleashSection.vue";
// import SecureAndAffordable from "../components/SecureAndAffordable.vue";
import FeaturesSection from "../components/FeaturesSection.vue";
// import WhatsNew from "../components/WhatsNew.vue";
// import FaqSection from "../components/FaqSection.vue";
// import ProductCarousel from '@/components/ProductCarousel.vue';
// import PaymentMethodsSection from '@/components/PaymentMethodsSection.vue'
import ImproveSection from '@/components/ImproveSection.vue'
import MoreOffers from '@/components/MoreOffers.vue';
export default {
  name: 'HomePage',
  components: {
    // RandomCasesSlider,
    // LastSales,
    // StatSection,
    // UnleashSection,
    // SecureAndAffordable,
    FeaturesSection,
    // WhatsNew,
    // FaqSection,
    // ProductCarousel,
    // PaymentMethodsSection,
    ImproveSection,
    MoreOffers
  },
  props: {
    currencySymbol: {
      type: String,
      required: true
    },
    isAuth: {
      type: Boolean,
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
  },
  data: function () {
    return {
      saleProducts: [],
      newArrivals: [],
      activePanels: [],
      faq: []
    }
  },
  computed: {
    ...mapGetters('app', ['currentLanguage']),
  },
  watch: {
    currencyCode() {
      this.getNewArrivals();
      this.getSale();
    },
  },
  async mounted() {
    this.getSale();
    this.getNewArrivals();
    this.getFaq();
    if (this.isAuth) {
      await this.fetchActiveDiscount(this.currencyCode);
      if (this.hasActiveDiscount) {
        this.openDiscountModal();
      }
    }
  },
  methods: {
    ...mapActions('discount', ['fetchActiveDiscount']),
    getFaq() {
      this.$http.get(process.env.VUE_APP_API + 'faqs' + '?lang_id=' + this.currentLanguage)
        .then((res) => {
          this.faq = res.data.payload
        })
        .catch(() => {

        })
    },
    getNewArrivals() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?new=true&currency=' + this.currencyCode)
        .then((res) => {
          this.newArrivals = res.data.payload;
        })
        .catch(() => {
        })
    },
    getSale() {
      this.$http.get(process.env.VUE_APP_API + 'items/list?sale=true&currency=' + this.currencyCode)
        .then((res) => {
          this.saleProducts = res.data.payload;
        })
        .catch(() => {
        })
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    },
    togglePanel(index) {
      if (this.isActivePanel(index)) {
        this.activePanels.splice(this.activePanels.indexOf(index), 1)
      } else {
        this.activePanels.push(index)
      }
    },
    isActivePanel(index) {
      return this.activePanels.includes(index)
    },
  }
}
</script>

<style scoped lang="scss">
.hero-image {
  user-drag: none;
  -webkit-user-drag: none;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  /* Safari and Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer and Microsoft Edge */
  -o-user-select: none;
  /* Opera */
  cursor: default;
  /* Changes the cursor to default, indicating no action */
}

.hero-top {
  position: absolute;
  top: -14px;
  right: 0;
}

.hero-bottom {
  position: absolute;
  top: 180px;
  right: 74px;
}
</style>